import { Layout, MetaDataProps } from 'src/components/Layout/Layout'
import { PageProps, graphql } from 'gatsby'

import { ContactCard } from 'src/components/ContactCard/ContactCard'
import { ContactUs } from 'src/components/ContactUs/ContactUs'
import React from 'react'
import { Testimonials } from 'src/components/Testimonials/Testimonials'

const ContactUsPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const flatData = data.squidex?.queryLandingContents?.[0]?.flatData || {}

  return (
    <Layout title="Contact Us | RabbitPeepers" meta={flatData}>
      <ContactUs />
      <ContactCard />
      <Testimonials />
    </Layout>
  )
}

export default ContactUsPage

type DataProps = {
  squidex: {
    queryLandingContents: Array<{
      flatData: MetaDataProps
    }>
  }
}

export const query = graphql`
  query {
    squidex {
      queryLandingContents {
        flatData {
          metaTitle
          metaDescription
          metaImage {
            ...SiteMetaImage
          }
        }
      }
    }
  }
`
