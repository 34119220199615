/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import { ContactUsForm } from 'src/components/ContactUs/ContactUsForm'
import { Container } from 'src/components/common/Container'
import { ShadowBox } from 'src/components/common/ShadowBox'
import { mq } from 'src/utils/mq'

const wrapper = css`
  margin-bottom: 80px;

  ${mq.md} {
    margin-bottom: 0;
    padding: 0 16px;
  }

  ${mq.lg} {
    padding: 0 24px 0 0;
  }

  ${mq.xl} {
    padding: 0 50px 0 0;
  }
`

export const ContactUs: React.FC = () => {
  return (
    <Container css={wrapper}>
      <ShadowBox>
        <ContactUsForm title="How can we serve you?" />
      </ShadowBox>
    </Container>
  )
}
